import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Card } from './Card';
import { Pagination } from "swiper/modules";
import './CardSwiper.sass';
import { useTranslation } from "react-i18next";

const CardSwiper = () => {
  const { t } = useTranslation();

  const menuItems = [
    { id: 1, title: t("performance_test_title"), content: t("performance_test_description") },
    { id: 2, title: t("load_test_title"), content: t("load_test_description") },
    { id: 3, title: t("peak_load_test_title"), content: t("peak_load_test_description") },
    { id: 5, title: t("overload_test_title"), content: t("overload_test_description") },
    { id: 6, title: t("longtime_stability_test_title"), content: t("longtime_stability_test_description") },
    { id: 7, title: t("sql_injection_test_title"), content: t("sql_injection_test_description") },
    { id: 8, title: t("user_permissions_test_title"), content: t("user_permissions_test_description") },
    { id: 9, title: t("responsiveness_test_title"), content: t("responsiveness_test_description") },
    { id: 10, title: t("cross_browser_test_title"), content: t("cross_browser_test_description") },
    { id: 11, title: t("ui_ux_accessibility_test_title"), content: t("ui_ux_accessibility_test_description") },
    { id: 12, title: t("form_validation_test_title"), content: t("form_validation_test_description") },
    { id: 13, title: t("code_review_test_title"), content: t("code_review_test_description") },
    { id: 14, title: t("php_injection_test_title"), content: t("php_injection_test_description") },
  ];

  return (
    <div className="offer-swiper-container">
      <Swiper
        modules={[Pagination]}
        spaceBetween={30}
        navigation
        pagination={{ clickable: true }}
        breakpoints={{
          500: { slidesPerView: 1, slidesPerGroup: 1 },
          900: { slidesPerView: 2, slidesPerGroup: 2 },
          1600: { slidesPerView: 3, slidesPerGroup: 3 },
        }}
      >
        {menuItems.map((item) => (
          <SwiperSlide key={item.id}>
            <Card title={item.title} description={item.content} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CardSwiper;

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import cookiesDOC from "../../assets/docs/cookies.pdf";

import "./CookiesBar.sass";

const CookieBar = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="cookie-bar">
        <p>
          {t("cookiesBar.consent_message")}{" "}
          <a href={cookiesDOC} target="_blank" rel="noreferrer">{t("cookiesBar.learn_more")}</a>
        </p>
        <button onClick={handleAccept}>{t("cookiesBar.accept")}</button>
      </div>
    )
  );
};

export default CookieBar;

import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import "./Landing.sass";
import { Banners } from "./Banners";

const Landing = () => {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const landingText = [
    t("colecting_data") + "\n\n " + t("never_reverse"),
    t("solutions"),
    t("solutions_2"),
  ];

  const [landingTextIndex, setlandingTextIndex] = useState(0);

  const [paramContent, setParamContent] = useState(null);

  useEffect(() => {
    const updateParamContent = () => {
      if (param[currentLanguage]) {
        setParamContent(param[currentLanguage]());
      } else {
        setParamContent(<p>Brak tekstu dla tego języka</p>);
      }
    };

    updateParamContent();

    const languageChangeHandler = () => {
      updateParamContent();
    };

    i18n.on("languageChanged", languageChangeHandler);

    return () => {
      i18n.off("languageChanged", languageChangeHandler);
    };
  }, [currentLanguage, i18n]);

  const buttonSetOnClick = (e) => {
    const buttons = document.querySelectorAll(".button-set__button");
    const index = e.target.dataset.index;

    buttons.forEach((button) => button.classList.remove("active"));
    buttons[index].classList.add("active");
    setlandingTextIndex(index);
  };

  const param = {
    pl: () => {
      return (
        <p>
          Jesteśmy <strong>Liderem Bezpieczeństwa Państwa</strong> z nagrodą w
          kategorii “Innowacyjna firma 2024.” Posiadamy certyfikaty{" "}
          <strong>ISO 9001:2015</strong> oraz{" "}
          <strong>ISO/IEC 27001:2023</strong>, potwierdzające zaangażowanie w
          utrzymanie najwyższych standardów jakości i bezpieczeństwa.
        </p>
      );
    },
    en: () => {
      return (
        <p>
          We have <strong>ISO 9001:2015</strong> and{" "}
          <strong>ISO/IEC 27001:2023</strong> certificates, confirming our
          commitment to maintaining the highest quality and security standards.
          We are the <strong>Leader of State Security</strong> with an award in
          the category of “Innovative Company 2024.”
        </p>
      );
    },
  };

  return (
    <div className="landing">
      <Header />
      <div className="landing__container">
        <h1 className="heading-fix">
          {" "}
          {t("your_protect")}
        </h1>
        {paramContent}
        <Banners />
        <a href={`#${t("contact2")}`} className="free-consultation-button">
          {t("free_consultation")}
        </a>
      </div>
    </div>
  );
};

export default Landing;

import { useTranslation } from "react-i18next";
import modules from './Certificate.module.scss';
import ISO from '../../assets/images/ISO/cert_1.png';
import ISO_EN from '../../assets/images/ISO/cert_1_EN.png';


export default function Certificate() {
  const { t, i18n } = useTranslation();
  
  var iso;
  if (i18n.language === "pl") {
    iso = ISO;
  } else {
    iso = ISO_EN;
  }

  return (
    <section className={modules.isoWrapper} id={t("nav.cert_href")}>
      <div className={`${modules.header} ${modules.bold}`}>
        <h1 className={modules.bold}>{t("certificate.header")}</h1>
        <p className={`${modules.subtitle} ${modules.bold}`}>
          {t("certificate.description")}
        </p>
      </div>

      <div className={modules.content}>
        <div className={modules.image}>
          <img src={iso} alt={t("certificate.image_alt")} />
        </div>
        <div className={modules.text}>
          <h2 className={modules.bold}>{t("certificate.what_is_iso")}</h2>
          <ul>
            <li>
              <strong>{t("certificate.iso_9001_title")}</strong> – {t("certificate.iso_9001_description")}
            </li>
            <li>
              <strong>{t("certificate.iso_27001_title")}</strong> – {t("certificate.iso_27001_description")}
            </li>
          </ul>
          <br />
          <h2 className={modules.bold}>{t("certificate.what_it_means")}</h2>
          <ul>
            <li>
              <strong>{t("certificate.reliability_title")}</strong> – {t("certificate.reliability_description")}
            </li>
            <li>
              <strong>{t("certificate.security_title")}</strong> – {t("certificate.security_description")}
            </li>
            <li>
              <strong>{t("certificate.compliance_title")}</strong> – {t("certificate.compliance_description")}
            </li>
            <li>
              <strong>{t("certificate.innovation_title")}</strong> – {t("certificate.innovation_description")}
            </li>
          </ul>
        </div>
      </div>

      <p className={`${modules.bold} ${modules.ending}`}>
        {t("certificate.commitment")}
      </p>
    </section>
  );
}

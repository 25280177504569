// FRAMEWORKS
import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n/config";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TagManager from "react-gtm-module";

// DOCS
import cookiesDOC from "./assets/docs/cookies.pdf"

// COMPONENTS
import Home from "./views/Home/Home";

// STYLES
import "./global.sass";

// GOOGLE TAG MANAGER
const tagManagerArgs = {
  gtmId: "GTM-M9CVLQB7",
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/polityka-prywatnosci"
          Component={() => {
            window.location.href = cookiesDOC;
            return null;
          }}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

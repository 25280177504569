import { useTranslation } from "react-i18next";

// COMPONENT
import { useEffect } from "react";

// STYLES
import "./Header.sass";

// IMAGES
import logo from "../../assets/images/logo.png";
import logo_en from "../../assets/images/logo_en.webp";
import polishFlag from "../../assets/icons/National_Flag_of_Poland.png";
import englishFlag from "../../assets/icons/great_britan_flag.png";

const Header = () => {
  const { t, i18n } = useTranslation();

  const LanguageSwitch = () => {
    const newLanguage = i18n.language === "pl" ? "en" : "pl";
    window.localStorage.setItem("lang", newLanguage);
    window.location = "/";
    i18n.changeLanguage(newLanguage);
  };

  const expandCollapseMenu = () => {
    document.querySelector("nav").classList.toggle("expanded");
    document.querySelector(".svg").classList.toggle("svg--active");
  };

  useEffect(() => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);

    if (queryParams.has("eng") && queryParams.get("eng") === "true") {
      window.localStorage.setItem("lang", "en");
      window.location = "/";
      i18n.changeLanguage("en");
    }

    if (window.scrollY > 10)
      document.querySelector(".header").className = "header header--not-on-top";
    const scrollListener = window.addEventListener("scroll", () => {
      if (window.scrollY > 10)
        document.querySelector(".header").className =
          "header header--not-on-top";
      else document.querySelector(".header").className = "header";
    });
  }, []);
  var logo2;
  if (i18n.language === "pl") {
    logo2 = logo;
  } else {
    logo2 = logo;
  }
  return (
    <header className="header">
      <a href="/">
        <img className="logo" src={logo2} alt="Cyberbexpieczeństwo" />
      </a>
      <div className="header__mobile-nav-button" onClick={expandCollapseMenu}>
        <svg width="40" height="32" className="svg">
          <rect className="svg__rect" width="40" height="3"></rect>
          <rect className="svg__rect" width="40" height="3"></rect>
          <rect className="svg__rect" width="40" height="3"></rect>
        </svg>
      </div>
      <nav onClick={expandCollapseMenu}>
        <a className="header__option" href={t("about1")}>
          {t("about")}
        </a>
        <a className="header__option" href={`#${t("nav.about_audit_href")}`}>
          {t("nav.about_audit")}
        </a>
        <a className="header__option" href={`#${t("nav.cert_href")}`}>
          {t("nav.cert")}
        </a>{" "}
        <a className="header__option" href={`#${t("nav.why_href")}`}>
          {t("nav.why")}
        </a>
        {/* <a className="header__option" href={t("audit1")}>
          {t("audit")}
        </a> */}
        {/* <a className="header__option" href={t("trust_us1")}>
          {t("trust_us")}
        </a> */}
        <a className="header__option" href={t("contact1")}>
          {t("contact")}
        </a>
        <div className="header__language-flags">
          <a onClick={LanguageSwitch} className="header__flag" title="Polski">
            <div className="header__flag__text">PL</div>
            <img src={polishFlag} alt="Polish Flag" />
          </a>
          <a onClick={LanguageSwitch} className="header__flag" title="English">
            <div className="header__flag__text">ENG</div>
            <img src={englishFlag} alt="English Flag" />
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Header;

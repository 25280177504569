// STYLES
import './CardSwiper.sass';

export const Card = ({title, description}) => {
  return (
    <div className="card-element">
      <div>
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
    </div>
  )
}
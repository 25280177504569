import { useTranslation } from "react-i18next";
import Heading from "../Heading/Heading";

import "./WhySection.sass";

export const WhySection = () => {
  const { t } = useTranslation();

  return (
    <section id={t("nav.why_href")}>
      <Heading start="left">
        <h2>{t("why.header").toLocaleUpperCase()}</h2>
      </Heading>
      <ul className="olist custom-olist">
        <li>
          <span style={{ fontWeight: "bold", color: "#0c6dae" }}>
            {t("why.risk_minimization_title")} {" "}
          </span>
          - {t("why.risk_minimization_description")}
        </li>
        <li>
          <span style={{ fontWeight: "bold", color: "#0c6dae" }}>
            {t("why.regulatory_compliance_title")} {" "}
          </span>
          - {t("why.regulatory_compliance_description")}
        </li>
        <li>
          <span style={{ fontWeight: "bold", color: "#0c6dae" }}>
            {t("why.it_infrastructure_protection_title")} {" "}
          </span>
          - {t("why.it_infrastructure_protection_description")}
        </li>
        <li>
          <span style={{ fontWeight: "bold", color: "#0c6dae" }}>
            {t("why.increased_efficiency_title")} {" "}
          </span>
          - {t("why.increased_efficiency_description")}
        </li>
        <li>
          <span style={{ fontWeight: "bold", color: "#0c6dae" }}>
            {t("why.employee_awareness_title")} {" "}
          </span>
          - {t("why.employee_awareness_description")}
        </li>
        <li>
          <span style={{ fontWeight: "bold", color: "#0c6dae" }}>
            {t("why.data_security_title")} {" "}
          </span>
          - {t("why.data_security_description")}
        </li>
        <li>
          <span style={{ fontWeight: "bold", color: "#0c6dae" }}>
            {t("why.partner_trust_title")} {" "}
          </span>
          - {t("why.partner_trust_description")}
        </li>
      </ul>

      <p className="summary">{t("why.summary")}</p>
      <a href={`#${t("contact2")}`} className="free-consultation-button">
        {t("free_consultation")}
      </a>
    </section>
  );
};
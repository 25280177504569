// STYLES
import './Heading.sass';

const Heading = ({children, start, customClass}) => {
  return (
    <div className={`heading__container heading__container--${start}`}>
      <div className={`heading heading--${start} ${customClass ? customClass : ''}`}>
        {children}
      </div>
    </div>
    
  )
}

export default Heading;
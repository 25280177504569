import { useTranslation } from "react-i18next";
import { useState } from "react";

// COMPONETS
import Heading from "../../Components/Heading/Heading";
import Landing from "../../Components/Landing/Landing";
import OfferCard from "../../Components/OfferCard/OfferCard";
import WydajnoscFrame from "../inHomeFrames/WydajnoscFrame";
import Footer from "../../Components/Footer/Footer";
import SkalowalnoscFrame from "../inHomeFrames/SkalowalnoscFrame";
import OgraniczeniaFrame from "../inHomeFrames/OgraniczeniaFrame";
import Certificate from "../../Components/Certificate/Certificate";

// STYLES
import "./Home.sass";

// IMAGES
import communication from "../../assets/icons/offerCards/bezpieczeństwo komunikacji.png";
import explot from "../../assets/icons/offerCards/bezpieczeństwo_eksploatacj.png";
import physical from "../../assets/icons/offerCards/bezpieczenstwo_fizyczne_i_srodowiskowe.png";
import systems from "../../assets/icons/offerCards/bezpieczenstwo_systemow_informatycznych.png";
import relation from "../../assets/icons/offerCards/bezpieczenstwo_w_relacjach_z_dostawcami.png";
import human from "../../assets/icons/offerCards/bezpieczenstwo_zasobow_ludzkich.png";
import access from "../../assets/icons/offerCards/kontrola_dostepu.png";
import safety from "../../assets/icons/offerCards/organizacja_bezpieczenstwa_informacyjnego.png";
import privacy from "../../assets/icons/offerCards/polityki_bezpieczenstwa.png";
import assets from "../../assets/icons/offerCards/zarzadzanie_aktywami.png";
import continuity from "../../assets/icons/offerCards/zarzadzanie_ciagloscia_dzialania.png";
import incydents from "../../assets/icons/offerCards/zarzadzanie_incydentami.png";
import law from "../../assets/icons/offerCards/zgodnosc_z_wymogami_prawnymi.png";

import zaufali from "../../assets/images/zaufali_nam.webp";
// DOCS

import CardSwiper from "../../Components/CardSwiper/CardSwiper";
import { WhySection } from "../../Components/WhySection/WhySection";
import { Contact } from "../../Components/Contact/Contact";

const Home = () => {
  const { t } = useTranslation();
  const consultation = t("consultation.list1", { returnObjects: true });

  return (
    <div className="home">
      <Landing />
      <div className="content">
        <section className="section--2col" id={t("about2")}>
          <div>
            <Heading start="left">
              <h2>{t("iso_info.about")}</h2>
              <p>
                {t("iso_info.first_paragraph")}
                <span style={{ color: "#0c6dae" }}>
                  {t("iso_info.first_paragraph_2")}
                </span>
                {t("iso_info.first_paragraph_3")}
                <br />
                <br />
                {t("iso_info.second_paragraph")}
                <br />
              </p>
            </Heading>
            {/* <br /> */}
            {/* <p className="margin-left-30">
              <b>{t("iso_info.subtitle")}</b>
            </p> */}
            {/* <ul className="olist">
              {Object.entries(services).map(([key, value]) => (
                <li key={key}>
                  <strong>{value.bold}</strong>
                  {value.text}
                </li>
              ))}
            </ul> */}
            {/* <p className="margin-left-30">
              <b>{t("iso_info.summary")}</b>
            </p> */}
          </div>
          <div className="img-placeholder"></div>
        </section>
        <section id={t("nav.about_audit_href")} className="audits">
          <Heading start="right" customClass={"wider"}>
            <h2>{t("order_audit_header")}</h2>
            <p style={{ width: "100%" }}>
              <span style={{ color: "#0c6dae" }}>
                {t("order_audit_description.el1")}
              </span>
              {t("order_audit_description.el2")}
              <span style={{ color: "#0c6dae" }}>
                {t("order_audit_description.el3")}
              </span>
              {t("order_audit_description.el4")}
            </p>
          </Heading>
          <div className="offer-grid">
            <OfferCard
              icon={privacy}
              title={t("offerCards_1.el1.title")}
              description={t("offerCards_1.el1.text")}
            />

            <OfferCard
              icon={safety}
              title={t("offerCards_1.el2.title")}
              description={t("offerCards_1.el2.text")}
            />

            <OfferCard
              icon={access}
              title={t("offerCards_1.el3.title")}
              description={t("offerCards_1.el3.text")}
            />

            <OfferCard
              icon={physical}
              title={t("offerCards_1.el4.title")}
              description={t("offerCards_1.el4.text")}
            />

            <OfferCard
              icon={communication}
              title={t("offerCards_1.el5.title")}
              description={t("offerCards_1.el5.text")}
            />

            <OfferCard
              icon={continuity}
              title={t("offerCards_1.el6.title")}
              description={t("offerCards_1.el6.text")}
            />

            <OfferCard
              icon={assets}
              title={t("offerCards_1.el7.title")}
              description={t("offerCards_1.el7.text")}
            />

            <OfferCard
              icon={relation}
              title={t("offerCards_1.el8.title")}
              description={t("offerCards_1.el8.text")}
            />

            <OfferCard
              icon={systems}
              title={t("offerCards_1.el9.title")}
              description={t("offerCards_1.el9.text")}
            />

            <OfferCard
              icon={human}
              title={t("offerCards_1.el10.title")}
              description={t("offerCards_1.el10.text")}
            />

            <OfferCard
              icon={explot}
              title={t("offerCards_1.el11.title")}
              description={t("offerCards_1.el11.text")}
            />

            <OfferCard
              icon={law}
              title={t("offerCards_1.el12.title")}
              description={t("offerCards_1.el12.text")}
            />

            <OfferCard
              icon={incydents}
              title={t("offerCards_1.el13.title")}
              description={t("offerCards_1.el13.text")}
            />
          </div>
        </section>

        <section id="#audyt-bezpieczenstwa">
          <Heading start="right">
            <h2>{t("audit_results_header")}</h2>
            <p>{t("audit_results_description")}</p>
            <br />
          </Heading>
          <CardSwiper />
        </section>

        {/* <section id={t("efects2")}>
          <Heading start="left">
            <h2>{t("test_results_header")}</h2>
            <p>{t("test_results_description")}</p>
          </Heading>
          <SlidingMenu content={effects} />
        </section> */}
        <section
          className="section--2col custom-section"
          id={t("consultation")}
        >
          <div className="content-special">
            <Heading start="left">
              <h2>{t("consultation.header")}</h2>
              <p>
                {t("consultation.paragraph.1")}
                <strong>{t("consultation.paragraph.2")}</strong>
                {t("consultation.paragraph.3")}
              </p>
            </Heading>
            <br />
            <ul className="olist">
              {Object.entries(consultation).map(([key, value]) => (
                <li key={key}>
                  <strong>{value.bold}</strong>
                  {value.text}
                </li>
              ))}
            </ul>
            <p style={{ fontSize: "25px" }}>
              <b style={{whiteSpace: 'pre-line'}}>{t("consultation.summary")}</b>
            </p>
            <br />
            <a
              href={`#${t("contact2")}`}
              className="free-consultation-button"
              style={{ marginTop: "1rem !important" }}
            >
              {t("free_consultation")}
            </a>
          </div>
          <div className="img-placeholder2 custom-img-placeholder"></div>
        </section>

        <Certificate />
        <WhySection />

        {/* <section id="audyt-bezpieczenstwa">
          <Heading start="right">
            <h2>{t("final_provisions")}</h2>
            <p>{t("customize_all_services")}</p>
          </Heading>
          <p>
            <h2>{t("offer_shows_general_capabilities")}</h2>
            <ul className="olist">
              <li>
                <u>{t("information")}</u>, {t("most_needed_tests_services")}
              </li>
              <li>
                <u>{t("app_information")}</u>, {t("goal_of_our_tests")}
              </li>
              <li>
                <u>{t("website_information")}</u>, {t("related_to_application")}
              </li>
              <li>
                {t("according_to_art")} <u>{t("discuss_the_entire_process")}</u>{" "}
                ({t("physically_or_online")}),
                {t("current_issues_with_the_application_etc")}
              </li>
            </ul>
          </p>
        </section> */}
        <section id={t("trust_us2")}>
          <Heading start="right">
            <h2>{t("why_us")}</h2>
            <p>{t("many_people_and_companies_have_trusted_us")}</p>
          </Heading>
          <img
            className="section__img"
            src={zaufali}
            alt="Firmy które nam zaufały"
          />
        </section>
        <Contact />
      </div>

      <Footer />

      <a className="mobileContact" href="#kontakt">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-envelope-fill"
          viewBox="0 0 16 16"
        >
          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
        </svg>
      </a>
    </div>
  );
};

export default Home;

import Heading from "../Heading/Heading";
import rodoDOC from "../../assets/docs/RODO.pdf";
import { useTranslation } from "react-i18next";
import { useState, useRef } from "react";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

export const Contact = () => {
  const { t } = useTranslation();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState(t("interested_contact_me"));
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const recaptchaRef = useRef(null);

  const handleCaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const validateForm = () => {
    let isValid = true;
    if (!/\S+@\S+\.\S+/.test(email)) {
      document.querySelector("label[for=email]").style.color = "red";
      isValid = false;
    } else {
      document.querySelector("label[for=email]").style.color = "";
    }

    if (phone && phone.length < 8) {
      document.querySelector("label[for=phone]").style.color = "red";
      isValid = false;
    } else {
      document.querySelector("label[for=phone]").style.color = "";
    }

    return isValid;
  };

  const handleSubmit = async () => {
    if (!privacyChecked) {
      alert("Proszę potwierdzić zgodę na przetwarzanie danych.");
      return;
    }

    if (!recaptchaValue) {
      Swal.fire({
        text: t("recaptcha_validation_error"),
        icon: "error",
        toast: true,
        timer: 2500,
        position: "top",
        showConfirmButton: false,
      });
      return;
    }

    if (!validateForm()) {
      Swal.fire({
        text: t("form_invalid_error"),
        icon: "error",
        toast: true,
        timer: 2500,
        position: "top",
        showConfirmButton: false,
      });
      return;
    }

    try {
      const response = await fetch("https://360totalsecurity.pl/sendmail/index.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, email, phone, msg, recaptcha_value: recaptchaValue }),
      });
      const result = await response.json();

      Swal.fire({
        text: result.msg,
        icon: result.status === "success" ? "success" : "error",
        toast: true,
        timer: 2500,
        position: "top",
        showConfirmButton: false,
      });

      if (result.status === "success") {
        setName("");
        setEmail("");
        setPhone("");
        setMsg(t("interested_contact_me"));
        setRecaptchaValue(null);
        if (recaptchaRef.current) recaptchaRef.current.reset();
      } else {
        if (recaptchaRef.current) recaptchaRef.current.reset();
      }
    } catch (error) {
      Swal.fire({
        text: "Wystąpił błąd. Spróbuj ponownie później.",
        icon: "error",
        toast: true,
        timer: 2500,
        position: "top",
        showConfirmButton: false,
      });
    }
  };

  return (
    <section id={t("contact2")}>
      <Heading start="left">
        <h2>{t("contact_us")}</h2>
        <p>{t("use_the_contact_form_below")}</p>
      </Heading>
      <div className="contact">
        <label htmlFor="name">{t("name")}</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />

        <label htmlFor="email">{t("email_address")}</label>
        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />

        <label htmlFor="phone">{t("phone_number")}</label>
        <input type="text" value={phone} onChange={(e) => setPhone(e.target.value.replace(/\D/g, ""))} />

        <label htmlFor="msg">{t("message_content")}</label>
        <textarea value={msg} onChange={(e) => setMsg(e.target.value)} />

        <div className="privacy-container" style={{ margin: "30px 30px" }}>
          <input type="checkbox" id="privacy" checked={privacyChecked} onChange={(e) => setPrivacyChecked(e.target.checked)} />
          <label htmlFor="privacy">
            {t("privacy.consent_statement")} <a href={rodoDOC} target="_blank" rel="noreferrer">{t("privacy.privacy_policy")}</a> {t("privacy.by_company")} BluSoft Sp. z o.o. {t("privacy.data_voluntary_comms")}
          </label>
        </div>

        <ReCAPTCHA
          sitekey="6LeuL94qAAAAALragRurINiF6wCQE404AFLMRMQB"
          ref={recaptchaRef}
          onChange={handleCaptchaChange}
        />

        <button onClick={handleSubmit} disabled={!privacyChecked} style={{ cursor: !privacyChecked ? "not-allowed" : "pointer" }}>
          {t("send_button")}
        </button>
      </div>
    </section>
  );
};

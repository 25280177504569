import iso from '../../assets/images/banners/CyberBezpieczeństwo_ISO.png';
import lbp from '../../assets/images/banners/CyberBezpieczeństwo_LBP.png';

import "./Landing.sass";

export const Banners = () => {
  return (
    <div className="banners">
      <img src={iso} alt="ISO Certification Banner" />
      <img src={lbp} alt="LBP Certification Banner" />
    </div>
  );
};
